import React from "react"
import { createUseStyles } from "react-jss"

const useStylesSpacer = createUseStyles((theme) => ({
  spacer: {
    cursor: 'default',
    color: theme.palette.primary.main,
    margin: {
      left: 18,
      right: 18,
    },
  },
}))

export const FooterSpacer = () => {
  const classes = useStylesSpacer()
  return <span className={classes.spacer}>|</span>
}