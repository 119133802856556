import PropTypes from "prop-types"
import React from "react"
import { Container } from "../container"
import { createUseStyles } from "react-jss"
import SEO from "../seo"
import { NormalHeader } from "./normalHeader"
import { MobileHeader } from "./mobileHeader"
import { HeaderLogo } from "./headerLogo"
import { WithLink } from "../testimonials/withLink"

const useStyles = createUseStyles((theme) => ({
  root: {
    zIndex: 2,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.white,
    borderBottom: [
      [1, "solid", theme.palette.primary.main],
    ],
  },
  bar: {
    padding: {
      top: 24,
      bottom: 24,
    },
  },
  logo: {
    margin: {
      top: 12,
      bottom: 12,
    },
  },
  title: {
    margin: 0,
  },
  normalHeader: {
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      display: "none",
    },
  },
  mobileHeader: {
    [`@media (min-width: ${theme.vars.mobileWidth + 1}px)`]: {
      display: "none",
    },
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

const links = [
  { name: "Home", href: "/" },
  { name: "Über Mich", href: "/#about" },
  { name: "Leistungen", href: "/#output" },
  { name: "Referenzen", href: "/#reference" },
  { name: "Kontakt", href: "/#contact" },
]

const Header = ({ siteTitle, index }) => {
  const classes = useStyles()

  return (
    <header className={classes.root}>
      <SEO title={siteTitle} index={index}/>
      <Container>
        <div className={classes.headerContent}>
          <WithLink url={'/'}>
            <HeaderLogo imgProps={{maxWidth: 'calc(100vw - 48px - 36px - 24px)',}} className={classes.logo} />
          </WithLink>

          <div className={classes.bar}>
            <NormalHeader links={links} className={classes.normalHeader}/>
            <MobileHeader links={links} className={classes.mobileHeader}/>
          </div>
        </div>
      </Container>
    </header>)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  index: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
