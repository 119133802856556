import React from "react"
import { createUseStyles } from "react-jss"
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  root: {
    margin: [0, 'auto'],
    maxWidth: 1100,
    padding: {
      left: 24,
      right: 24,
    },
  },
  paddingSmall: {
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      padding: {
        left: 24,
        right: 24,
      }
    }
  },
  noPaddingSmall: {
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      padding: {
        left: 0,
        right: 0,
      }
    }
  }
}))

export const MobilePadding = (props) => {
  const classes = useStyles();
  
  return <div className={classes.paddingSmall} {...props}/>
}

export const Container = ({mobileNoPadding, ...props}) => {
  const classes = useStyles();
  
  return (
    <div
      className={classNames(classes.root, {[classes.noPaddingSmall]: mobileNoPadding})}
      {...props}
    />
  )
}