import { createUseStyles } from "react-jss"
import React from "react"
import { Container } from "../container"
import { MyA } from "../mdComponents/myA"
import { FooterLink } from "./footerLink"
import { FooterSpacer } from "./footerSpacer"
import FIcon from "../../images/icons/facebook-f-brands.svg"
import LinkedInIcon from "../../images/icons/linkedin-brands.svg"

const useStyles = createUseStyles((theme) => ({
  root: {
    backgroundColor: "black",
    borderTop: [
      [1, "solid", theme.palette.primary.main],
    ],
    padding: {
      top: 36,
      bottom: 36,
    },
    color: "white",
  },
  space: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  elements: {
    margin: {
      top: 8,
      bottom: 8,
    },
  },
  icon: {
    display: "inline-box",
    height: 19,
    width: 19,
    margin: {
      left: 4,
      right: 4,
    },
  },
}))

const footerEntries = [
  {
    name: "Impressum",
    href: "/impressum/",
  },
  {
    name: "AGBs",
    href: "/agb",
  },
  {
    name: "Datenschutz",
    href: "/datenschutz/",
  },
]

export const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.root}>
      <Container>
        <div className={classes.space}>
          <span className={classes.elements}>
            {new Date().getFullYear()} © Copyrights
            {" "}
            <MyA whiteHover href="https://wenke-reichert.de/">Wenke Reichert</MyA>
          </span>
          <span className={classes.elements}>
          {footerEntries.map((e, i) => (
            <FooterLink key={e.href} {...e} />)).reduce((r, a, i, src) => i !== src.length - 1 ? r.concat(a,
            <FooterSpacer key={i}/>) : r.concat(a), [])}
        </span>
          <span className={classes.elements} style={{}}>
            <MyA whiteHover href={"https://www.facebook.com/wenke.reichert.9"} target={"_blank"}
                 rel={"noreferrer noopener"}><FIcon className={classes.icon}/></MyA>
            <MyA whiteHover href={"https://de.linkedin.com/in/wenke-reichert-2148aa193"} target={"_blank"}
                 rel={"noreferrer noopener"}><LinkedInIcon className={classes.icon}/></MyA>
        </span>
        </div>
      </Container>
    </footer>
  )
}