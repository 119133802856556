import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const useStyles = createUseStyles((theme) => ({
  root: {
    color: theme.palette.black,
    textDecoration: "none",
    transition: ["color", "250ms"],
    margin: {
      left: 16,
      right: 16,
    },
    [`@media (max-width: ${theme.vars.mobileWidth+100}px)`]: {
      margin: {
        lef: 8,
        right: 8,
      }
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))

export const HeaderLink = ({ href, name, className, ...props }) => {
  const classes = useStyles()

  return <Link
    className={classes.root + " " + className}
    to={href}
    {...props}
  >
    {name}
  </Link>
}

HeaderLink.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
}

