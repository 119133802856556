import React from "react"
import { createUseStyles } from "react-jss"
import classNames from 'classnames';
import {OutboundLink} from "gatsby-plugin-google-analytics"

const useStyles = createUseStyles((theme) => ({
  linkReset: theme.mixin.linkReset,
}))

export const WithLink = ({ children, className, url, ...props }) => {
  const styles = useStyles();
  
  return url ? <OutboundLink className={classNames(styles.linkReset, className)} {...props} href={url}>{children}</OutboundLink> : children
}