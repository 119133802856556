import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

export const HeaderLogo = (props) => {
  const data = useStaticQuery(graphql`
      query {
          logo: file(relativePath: { eq: "logos/logo-black-gold.png" }) {
              childImageSharp {
                  fixed(height: 65) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)

  return <Img {...props} fixed={data.logo.childImageSharp.fixed}/>
}