import { createUseStyles } from "react-jss"
import React from "react"
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  myA: {
    ...theme.mixin.linkReset,
    color: theme.palette.primary.main,
    transition: ['color',  '250ms'],
    '&:hover': {
      color: theme.palette.black,
    },
    '&:visited': {
      color: theme.palette.primary.main,
    }
  },
  white: {
    '&:hover': {
      color: theme.palette.white,
    },
    '&:active': {
      color: theme.palette.white,
    }
  }
}));

export const MyA = ({whiteHover, ...props}) => {
  const classes = useStyles();
  /* eslint-disable-next-line jsx-a11y/anchor-has-content */
  return <a className={classNames(classes.myA, {[classes.white]: whiteHover})} {...props} />
}